import { faArrowAltCircleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  auth,
  AUTHENTICATION_MS,
  callFunction,
  configLabel,
  KanplaError,
  rules,
  useRequest,
} from "@kanpla/system";
import {
  ActionsAuthenticationModal,
  AnonymousPropsFlow,
  DataAuthenticationModal,
} from "@kanpla/types";
import { Image, Spinner } from "@kanpla/ui";
import { Button, Form, Input, message } from "antd";
import { processProviders } from "apps/frontend/lib/signup/processProviders";
import { PartnerAccessCheckProps } from "apps/frontend/pages/api/internal/signup/canAccessPartner";
import { fetchSignInMethodsForEmail, getRedirectResult } from "firebase/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClickRef } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Helpcenter from "../Helpcenter";
import PageHeader from "../signup-flow/PageHeader";
import { providerSetup } from "../signup-flow/screens/Provider";
import PasswordInput from "./partial/PasswordInput";

const MultiLogin = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation([
    "log-in-page",
    "translation",
    "login",
    "anonymous",
  ]);

  const {
    customBranding,
    setDataAuthenticationModal,
    setOpenAuthenticationModal,
    auth: authHook,
    setModuleId,
  } = useContainer(AppContext);
  const router = useRouter();

  /**
   * checkInfo, useEffect, and signProviderIn are necessary for Microsoft login
   */
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const result = await getRedirectResult(auth);
        if (result?.user?.uid) router.push("/app");
      }
    });
  }, [router]);

  const [email, setEmail] = useState<string>(null);
  const [showButton, setShowButton] = useState(false);
  const [loadingMethods, setLoadingMethods] = useState(false);
  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState([]);
  const [processingMethod, setProcessingMethod] = useState<string>(null);
  const [ran, setRan] = useState(false);
  const [password, setPassword] = useState<string>(null);
  /** Shows a sign in with email button */
  const [initStep, setInitStep] = useState(true);
  const { request: checkCanAccess } = useRequest<
    PartnerAccessCheckProps,
    boolean
  >("signup/canAccessPartner");

  const [ref] = useOutsideClickRef(() => setShowButton(false));

  const checkAccess = async () => {
    try {
      if (!email || email === "")
        throw new KanplaError("kanpla/fill-email", "Fill out email address");
      setInitStep(false);
      setRan(true);
      setLoadingMethods(true);

      await checkCanAccess({
        email,
        partnerId: customBranding?.partnerId,
      });

      const loadedMethods = await fetchSignInMethodsForEmail(
        auth,
        email.trim()
      );

      setMethods(loadedMethods);
    } catch (err) {
      // User isn't created
      if (err?.code === "functions/not-found") {
        setMethods([]);
        return;
      }

      setInitStep(true);

      // Can't log user in
      if (err?.status === 401) {
        console.error(err);
        message.error(t("translation:message.error.partner-locked"));
        return;
      }

      const newError = new KanplaError(err?.code, err?.message);
      console.error(err);
      message.error(newError.message);
    } finally {
      setLoadingMethods(false);
    }
  };

  const signInWithPassword = async () => {
    try {
      if (!password || password === "")
        throw new KanplaError("kanpla/fill-password", "Fill out the password");
      setLoading(true);

      await authHook.signIn(email, password);
      setModuleId(null);
    } catch (err) {
      const newError = new KanplaError(err?.code, err?.message);
      console.error(newError);
      message.error(newError?.message);
    } finally {
      setLoading(false);
    }
  };

  const doProvider = async (providerName: string) => {
    setProcessingMethod(providerName);
    try {
      const res = await callFunction("signup-validateEmail", {
        email,
        partnerId: customBranding?.partnerId || null,
        onlyLogin: true,
      });

      let providers = [];

      // Check for multiple emails on domain
      if (Array.isArray(res)) {
        const check = res.some((d) => !d.alreadyExists);

        if (check) {
          router.push("/signup");
          throw new Error(t("login:message.error.account-no-exist"));
        }

        providers = res.map((d) => d?.providers || []).flat();
      } else {
        const { providers: singleProvider, alreadyExists } = res;

        providers = singleProvider;

        if (!alreadyExists) {
          router.push("/signup");
          throw new Error(t("login:message.error.account-no-exist"));
        }
      }

      await processProviders(providerName, providers, email);
      setModuleId(null);
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setProcessingMethod(null);
    }
  };

  /** Set the data of the authentication modal when the user is about to order */
  const setDataForAuthenticationModalAndOpenModal = (
    title: string,
    subtitle: string,
    action: ActionsAuthenticationModal,
    isFromAPrivateModule?: boolean
  ) => {
    if (!isFromAPrivateModule) setOpenAuthenticationModal(false);
    setTimeout(() => {
      setDataAuthenticationModal(() => {
        const newState: DataAuthenticationModal = {
          title,
          subtitle,
          action,
        };
        return newState;
      });
      if (!isFromAPrivateModule) setOpenAuthenticationModal(true);
    }, AUTHENTICATION_MS);
  };

  return (
    <>
      <PageHeader
        title={t("translation:welcome-back")}
        subtitle={t("translation:log-in-to-your-account")}
      />
      <Form
        layout="vertical"
        className={`${isFromAnonymousFlow || "max-w-xs"} my-8 m-auto`}
      >
        <Form.Item
          className="relative form-label-antd-h-unset"
          name="email"
          label="Email"
          labelCol={configLabel({ span: 24 })}
          initialValue={email}
          rules={[rules({ rule: "required", ruleValue: true, message: "" })]}
        >
          <div className="relative flex items-center" ref={ref}>
            <Input
              id="email"
              type="email"
              placeholder={t("translation:form.placeholders.email")}
              onFocus={() => setShowButton(true)}
              onChange={(e) => {
                setEmail(e.target.value);
                setInitStep(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  checkAccess();
                }
              }}
            />
            {showButton && email !== "" && (
              <Button
                className="absolute right-1 flex items-center justify-center px-2"
                type="link"
                onClick={checkAccess}
              >
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Button>
            )}
          </div>
        </Form.Item>

        {initStep && (
          <Form.Item>
            <Button
              size="large"
              type="primary"
              className="w-full"
              onClick={checkAccess}
              data-cy="continue-login"
            >
              {t("login:continue-with-email")}
            </Button>
          </Form.Item>
        )}

        {!initStep && loadingMethods && (
          <div className="flex items-center justify-center text-text-primary">
            <Spinner useCurrentColor size="small" />
          </div>
        )}

        {!initStep &&
          !loadingMethods &&
          (!methods || !methods?.length) &&
          ran &&
          (isFromAnonymousFlow ? (
            <Button
              onClick={() => {
                setDataForAuthenticationModalAndOpenModal(
                  t("translation:sign-up"),
                  t("anonymous:modal.subtitles.info-authenticate-signup"),
                  "signup"
                );
              }}
              size="large"
              type="primary"
              className="w-full"
            >
              {t("login:account-no-exist")}
            </Button>
          ) : isFromAPrivateModule ? (
            <Button
              onClick={() => {
                setDataForAuthenticationModalAndOpenModal(
                  t("translation:sign-up"),
                  t("anonymous:modal.subtitles.info-authenticate-signup"),
                  "signup",
                  isFromAPrivateModule
                );
              }}
              size="large"
              type="primary"
              className="w-full"
            >
              {t("login:account-no-exist")}
            </Button>
          ) : (
            <Link href="/signup">
              <Button size="large" type="primary" className="w-full">
                {t("login:account-no-exist")}
              </Button>
            </Link>
          ))}

        {!initStep &&
          !loadingMethods &&
          methods.map((method) => {
            if (method === "password")
              return (
                <div key="password-input">
                  <PasswordInput
                    autoFocus
                    password={password}
                    setPassword={setPassword}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        signInWithPassword();
                      }
                    }}
                  />
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      className="w-full"
                      onClick={signInWithPassword}
                      loading={loading}
                      data-cy="login-confirm"
                    >
                      {t("translation:log-in")}
                    </Button>
                  </Form.Item>
                </div>
              );

            return (
              <Button
                loading={processingMethod === method}
                key={method}
                onClick={() => doProvider(method)}
                size="large"
                className="flex mx-auto w-full"
                icon={
                  <Image
                    src={`/images/logos/${method}.svg`}
                    alt={`${method} Logo`}
                    className="mr-3"
                  />
                }
              >
                {t("translation:log-in-with", {
                  value: providerSetup[method]?.name || method,
                })}
              </Button>
            );
          })}

        <p className="mt-6 text-text-secondary flex flex-col">
          {t("log-in-page:dont-have-account")}{" "}
          {isFromAnonymousFlow ? (
            <span
              onClick={() => {
                setDataForAuthenticationModalAndOpenModal(
                  t("translation:sign-up-here"),
                  t("anonymous:modal.subtitles.info-authenticate-signup"),
                  "signup"
                );
              }}
              className="font-semibold text-lg text-primary-main cursor-pointer mt-3"
            >
              {t("translation:sign-up-here")}
            </span>
          ) : isFromAPrivateModule ? (
            <span
              onClick={() => {
                setDataForAuthenticationModalAndOpenModal(
                  t("translation:sign-up-here"),
                  t("anonymous:modal.subtitles.info-authenticate-signup"),
                  "signup",
                  isFromAPrivateModule
                );
              }}
              className="font-semibold text-lg text-primary-main cursor-pointer mt-3"
            >
              {t("translation:sign-up-here")}
            </span>
          ) : (
            <Link href="/signup">
              <a className="font-semibold underline underline-offset-2 text-primary-main cursor-pointer mt-3">
                {t("translation:sign-up-here")}
              </a>
            </Link>
          )}
        </p>
      </Form>

      <div className="mt-24">
        <Helpcenter.Intercom small />
      </div>
    </>
  );
};

export default MultiLogin;
