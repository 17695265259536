import { useLocationHostname } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import { domainsWithPublicSchools } from "../signup-flow";
import BasicLogin from "./BasicLogin";
import MultiLogin from "./MultiLogin";

const LoginContent = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const hostname = useLocationHostname({});
  const hasPublicSchools = domainsWithPublicSchools.includes(hostname);

  if (hasPublicSchools)
    return (
      <BasicLogin
        isFromAnonymousFlow={isFromAnonymousFlow}
        isFromAPrivateModule={isFromAPrivateModule}
      />
    );

  return (
    <MultiLogin
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    />
  );
};

export default LoginContent;
